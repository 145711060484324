<template>
  <list-layout :title="$t('orders.pageTitle')"
              :index-request="indexRequest"
              detail-to="orders.show"
              :crud-headers="crudHeaders">
    <template #flow-actions>
      <ExportOrdersButton/>
    </template>
  </list-layout>
</template>

<script>
import { clientIndex } from '@/modules/orders/api/order.js';
import ListLayout from '@/modules/orders/components/ListLayout.vue';
import { mapGetters } from 'vuex';
import ExportOrdersButton from '@/modules/orders/components/ExportOrdersButton.vue';

export default {
  name: 'OrdersList',
  components: { ExportOrdersButton, ListLayout },
  computed: {
    ...mapGetters('authorisation', { clientId: 'getCurrentClientId' }),
    crudHeaders() {
      return [
        {
          value: 'statusIcon',
          text: '',
          width: 48,
        },
        {
          value: 'dealerCodeExt',
          language: 'dealerCodeExt'
        },
        {
          value: 'orderStatusId',
          language: 'orderStatusId',
        },
        {
          value: 'storeName',
          language: 'storeName',
        },
        {
          value: 'userName',
          language: 'userName',
        },
        {
          value: 'countryName',
          language: 'countryName',
        },
        {
          value: 'date',
          language: 'date',
        },
      ];
    },
  },
  methods: {
    indexRequest(...args) {
      return clientIndex(this.clientId, ...args);
    },
  },
};
</script>
