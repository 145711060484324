<template>
  <k-crud-layout :search.sync="searchQuery" :filters.sync="filters">
    <template #header>{{ title }}</template>
    <template #flow-actions>
      <slot name="flow-actions"/>
    </template>
    <template #filters="{attrs, on}">
      <OrdersFilter
          v-bind="attrs"
          v-on="on"
          :show-store-filter="showStoreFilter"
      />
    </template>
    <template #view.list>
      <k-crud-table ref="table"
                    :headers="crudHeaders"
                    :index-request="indexRequest"
                    :search="searchQuery"
                    :params="filters"
                    language-prefix="orders.crudHeaders"
                    resource="orders">
        <template #item.statusIcon="{item: {orderStatusId}}">
          <v-row class="justify-center align-center">
            <v-col cols="auto" style="height: 48px">
              <OrderStatusIcon :orderStatusId="orderStatusId"/>
            </v-col>
          </v-row>
        </template>
        <template #item.orderStatusId="{item: {orderStatusId}}">
          <span>{{ $t(`orders.orderStatus.${getOrderStatus(orderStatusId)}`) }}</span>
        </template>
        <template #item.date="{item: {date}}">
          <span>{{ dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('LL') }}</span>
        </template>
        <template #actions="{item}" v-if="detailTo">
          <v-btn icon :to="{name: detailTo, params: {orderId: item.id}}">
            <v-icon>$visible</v-icon>
          </v-btn>
        </template>
      </k-crud-table>
    </template>
  </k-crud-layout>
</template>

<script lang="js">
import orderStatus from '@/application/enums/orderStatus.js';
import KCrudTable from '@/components/crud/KCrudTable.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import OrderStatusIcon from '@/modules/orders/components/OrderStatusIcon.js';
import dayjs from '@/plugins/dayjs.js';
import OrdersFilter from '@/modules/orders/components/OrdersFilter.vue';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';

export default {
  name: 'ListLayout',
  mixins: [querySyncMixin],
  components: {
    OrdersFilter,
    OrderStatusIcon,
    KCrudTable,
    KCrudLayout,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    indexRequest: {
      type: Function,
      required: true,
    },
    detailTo: {
      type: String,
    },
    crudHeaders: {
      type: Array,
      required: true,
    },
    showStoreFilter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filters: {},
      searchQuery: '',
    };
  },
  methods: {
    dayjs,
    getOrderStatus(id) {
      return Object.keys(orderStatus).find(key => orderStatus[key] === id);
    },
  },
};
</script>
